import React from 'react';
import PoweredBy from '../components/common/powered-by';
import SelectSlotStep from '../components/select-slot-step';
import { useContextState, useContextDispatch } from '../context';
import { createAppointment, getPracticeInfo } from '../api';
import { bookingCompleted, goBack, goToNextStep, setPractice } from '../context/store/actions';
import Progress from '../components/common/progress';
import NameForm from '../components/forms/name-form';
import ReasonForm from '../components/forms/reason-form';
import BookingCompleted from '../components/booking-completed';

const DefaultFlowContainer = () => {
    const {
        config: { practiceId, host, redirectUrl, widgetParams, hooks: { onBookingCompleted, onSlotSelected } },
        currentStep,
        isBookingCompleted,
    } = useContextState();
    const dispatch = useContextDispatch();
    React.useEffect(() => {
        getPracticeInfo(host, practiceId, widgetParams).then(practice => {
            dispatch(setPractice(practice));
        })
    }, []);

    const createAppointmentFunction = ({
           patient,
           appointmentType,
           slotId,
           reason,
           metrics,
           setLoading,
           setApiErrors,
       }) => {
        createAppointment(
            host,
            {
                patient,
                appointmentType,
                slotId,
                reason,
                metrics,
            },
            widgetParams
        )
            .then(res => {
                setLoading(false)
                if (res.id) {
                    if(redirectUrl) {
                        window.open(redirectUrl, '_self')
                    } else {
                        dispatch(bookingCompleted());
                    }
                    onBookingCompleted();
                }
                if (res.error) {
                    setApiErrors(res.reasons);
                    setTimeout(() => { setApiErrors([]) }, 4000)
                }
            })
            .catch(() => setLoading(false))
    }

    return (
        <>
            {(() => {
                switch (currentStep) {
                    case 0:
                        return <SelectSlotStep
                            action={() => {
                                dispatch(goToNextStep());
                                onSlotSelected();
                            }}
                        />
                    case 1:
                        return (
                            <>
                                <Progress
                                    steps={3}
                                    currentStep={currentStep}
                                    showGoBack
                                    onGoBack={() => dispatch(goBack())}
                                />
                                <NameForm
                                    action={() => {
                                        dispatch(goToNextStep())
                                    }}
                                />
                            </>
                        );
                    case 2:
                        return (
                            <>
                                <Progress
                                    steps={3}
                                    currentStep={currentStep}
                                    showGoBack
                                    onGoBack={() => dispatch(goBack())}
                                />
                                <ReasonForm
                                    action={createAppointmentFunction}
                                />
                            </>
                        )
                    default:
                        return null;
                }
            })()}
            {isBookingCompleted ? <BookingCompleted /> : null}
            <PoweredBy />
        </>
    );
};


export default DefaultFlowContainer;

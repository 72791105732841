import React from 'react';
import { Loader } from './styles.css';


const Spinner = ({ borderInactive, borderActive }) => {
    return (
        <div
            className={Loader}
            style={{
                borderColor: borderInactive,
                borderTopColor: borderActive
            }}
        />
    )
};

export default Spinner;

import React from 'react';
import PoweredBy from '../components/common/powered-by';
import ErrorField from '../components/common/error-field';
import SelectSlotStep from '../components/select-slot-step';
import { useContextState, useContextDispatch } from '../context';
import { getAppointmentTypes, getPractices, authPatient, createAppointment } from '../api';
import {
    goBack,
    goToNextStep,
    setAppointmentTypes,
    authAttemptTrigger,
    bookingCompleted,
    setPractices,
} from '../context/store/actions';
import Progress from '../components/common/progress';
import NameForm from '../components/forms/name-form';
import ReasonForm from '../components/forms/reason-form';
import ZipCodeSearchForm from '../components/forms/zip-code-search-form';
import BeforeBookingDisclaimerForm from '../components/forms/before-booking-disclaimer-form';
import BookingCompleted from '../components/booking-completed-variant2';
import { getDate } from '../utils/helpers';
import { setCookie, eraseCookie } from '../utils/cookies';

const authCookieFailedKey = 'doctify-booking-widget-auth-failed'
const authCookieAttemptsKey = 'doctify-booking-widget-auth-attempts'
const authCookieTTL = 1 / 24 / 4 // 15 minutes

const SearchFirstFlowContainer = () => {
    const {
        config: {
            host,
            widgetParams,
            authUserRequired,
            authFailedText,
            authMaxAttempts,
            authMaxAttemptsExceededText,
            skipMobileField,
            metrics,
            redirectUrl,
            hooks: { onBookingCompleted },
        },
        patientForm,
        currentStep,
        authAttempts,
        isBookingCompleted,
        calendarOptions,
    } = useContextState();
    const dispatch = useContextDispatch();

    const [error, setError] = React.useState()
    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(() => {

    }, []);

    React.useEffect(() => {
        if (authAttempts >= authMaxAttempts) {
            setError(authMaxAttemptsExceededText)
        }
    }, [authAttempts]);

    const patientFormAction = React.useCallback(() => {
        if (authUserRequired) {
            setIsLoading(true);
            setError(null)
            const dob = getDate(patientForm?.dayOfBirth);
            const patientData = {
                lastName: patientForm.lastName,
                firstName: patientForm.firstName,
                email: patientForm.email,
                dob: dob.formattedDate,
                zipCode: patientForm.zipCode,
            }
            dispatch(authPatient(host, patientData, widgetParams, (permit) => {
                if (permit?.success) {
                    eraseCookie(authCookieAttemptsKey, window.location.pathname)
                    eraseCookie(authCookieFailedKey, window.location.pathname)
                    dispatch(goToNextStep());
                } else if (!permit?.success && permit.reason) {
                    setError(permit.reason);
                } else {
                    if (+authAttempts + 1 < authMaxAttempts) {
                        setError(authFailedText);
                        setCookie(authCookieAttemptsKey, +authAttempts + 1, authCookieTTL, window.location.pathname)
                        dispatch(authAttemptTrigger())
                    } else {
                        if (+authAttempts + 1 === authMaxAttempts) {
                            setCookie(authCookieAttemptsKey, +authAttempts + 1, authCookieTTL, window.location.pathname)
                            dispatch(authAttemptTrigger())
                        }
                        setError(authMaxAttemptsExceededText)
                        setCookie(authCookieFailedKey, true, authCookieTTL, window.location.pathname)
                    }
                }
                setIsLoading(false);
            }));
        } else {
            dispatch(goToNextStep());
        }
    }, [patientForm, authUserRequired, authAttempts])

    const zipCodeFormAction = React.useCallback(() => {
        getAppointmentTypes(host, {}, widgetParams)
            .then(types => {
                dispatch(setAppointmentTypes(types));
            })
        getPractices(host, {
            zipCode: patientForm.zipCodeSearch,
        }, widgetParams)
            .then(practices => {
                dispatch(setPractices(practices))
            })
        dispatch(goToNextStep());
    }, [patientForm])

    const createAppointmentFunction = React.useCallback(() => {
        setIsLoading(true)
        const { reason, dayOfBirth, phoneNumber, firstName, lastName, email, appointmentType } = patientForm
        const slotId = calendarOptions?.selectedSlot?.id;
        const dob = getDate(dayOfBirth);
        createAppointment(
            host,
            {
                patient: {
                    lastName: lastName,
                    firstName: firstName,
                    phone: phoneNumber,
                    email: email,
                    dob: dob.formattedDate
                },
                appointmentType,
                slotId,
                reason,
                metrics,
            },
            widgetParams
        )
            .then(res => {
                if (res.id) {
                    dispatch(goToNextStep())
                    if(redirectUrl) {
                        window.open(redirectUrl, '_self')
                    } else {
                        dispatch(bookingCompleted());
                    }
                    onBookingCompleted();
                }
                if (res.error) {
                    setError(res.reasons[0]);
                    setTimeout(() => { setError(null) }, 10000)
                }
            })
            .finally(() => setIsLoading(false))
    }, [patientForm, calendarOptions])

    return (
        <>
            {(() => {
                switch (currentStep) {
                    case 0:
                        return (
                            <>
                                <Progress
                                    steps={4}
                                    currentStep={currentStep}
                                />
                                <NameForm
                                    buttonDisabled={+authAttempts >= authMaxAttempts || isLoading}
                                    action={() => {
                                        dispatch(goToNextStep())
                                    }}
                                />
                            </>
                        );
                    case 1:
                        return (
                            <>
                                <Progress
                                    steps={4}
                                    currentStep={currentStep}
                                    showGoBack
                                    onGoBack={() => dispatch(goBack())}
                                />
                                <ReasonForm
                                    skipMobileField={skipMobileField}
                                    action={patientFormAction}
                                    buttonDisabled={+authAttempts >= authMaxAttempts || isLoading}
                                />
                            </>
                        )
                    case 2:
                        return (
                            <>
                                <Progress
                                    steps={4}
                                    currentStep={currentStep}
                                    showGoBack
                                    onGoBack={() => dispatch(goBack())}
                                />
                                <ZipCodeSearchForm
                                    buttonDisabled={+authAttempts >= authMaxAttempts || isLoading}
                                    action={zipCodeFormAction}
                                />
                            </>
                        )
                    case 3:
                        return (
                            <>
                                <BeforeBookingDisclaimerForm
                                    action={() => {
                                        dispatch(goToNextStep())
                                    }}
                                />
                            </>
                        )
                    case 4:
                        return (
                            <>
                                <div style={{ marginBottom: 20 }}>
                                    <Progress
                                        steps={4}
                                        currentStep={currentStep}
                                        showGoBack
                                        onGoBack={() => dispatch(goBack())}
                                    />
                                </div>
                                <SelectSlotStep
                                    buttonDisabled={isLoading}
                                    practicesDropdown={true}
                                    action={createAppointmentFunction}
                                />
                            </>
                        )
                }
            })()}
            <ErrorField error={error}/>
            {isBookingCompleted ? <BookingCompleted /> : null}
            {currentStep !== 3 ? <PoweredBy /> : null}
        </>
    );
};


export default SearchFirstFlowContainer;

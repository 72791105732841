import React from 'react';
import Button from './../common/button';
import { useContextState } from '../../context';
import { FormContainerWrapper } from '../../styles/form.css';
import { FormTextSection } from './styles.css';

const BeforeBookingDisclaimerForm = ({ action }) => {
    const {
        config: { beforeBookingDisclaimerText }
    } = useContextState();

    return (
        <div className={FormContainerWrapper}>
            <div
                className={FormTextSection}
                dangerouslySetInnerHTML={{ __html: beforeBookingDisclaimerText }}
            />
            <Button text="Next" onClick={() => {
                action();
            }} />
        </div>
    );
};

export default BeforeBookingDisclaimerForm;

export const defaultTheme = {
    textColor: '#0F3731',
    borderColor: '#0F3731',
    inputFontSize: 16,
    labelFontSize: 16,
    buttonFontSize: 18,
    primaryBackground: '#FFFAE7',
    secondaryBackground: '#fff',
    btnBackground: '#39CAB4',
    btnHoverBackground: '#30a899',
    showMoreBg: '#12ecd2',
    btnTextColor: '#F3F3F3',
    errorColor: '#ff0000',
    errorBackground: '#fff0f2',
    progressColor: '#39CAB4',
    errorFontSize: 12,
    titleFontSize: 30,
    fontSizeSm: 14,
    fontSize: 16,
    slotColor: '#FCCB0A',
    activeSlotColor: '#39CAB4',
    spinnerSecondaryColor: '#f3f3f3',
    spinnerPrimaryColor: '#39CAB4',
    slotBorder: 'none',
    activeSlotBorder: 'none'
};

const defaultHooks = {
    onSlotSelected: () => null,
    onBookingCompleted: () => null,
    onAddNewBooking: () => null
};

export const appointmentTypes = [{ label: 'Video consultation', value: 'video_consultation' }];
// { label: 'In person', value: 'in_person'},

export const flows = {
    DEFAULT: 'default',
    NUFFIELD: 'nuffield'
}

export const defaultConfig = {
    theme: defaultTheme,
    hooks: defaultHooks,
    termsAndConditionsLink: 'https://doctify.com/uk/info/terms-and-conditions',
    bookingCompletedDescription: 'Some description text. You are awesome! Thanks for booking!',
    slotSelectionTitle: 'Book a free video consultation from your home',
    patientFormTitle: 'Booking completion',
    reasonFormTitle: '',
    patientFormButtonText: 'Complete booking',
    zipCodeSearchFormButtonText: '',
    zipCodeSearchFormText: '',
    zipCodeSearchFormTitle: '',
    defaultCountry: 'gb',
    redirectUrl: '',
    flow: flows.DEFAULT,
    widgetParams: {},
    authUserRequired: false,
    authMaxAttempts: 5,
    authMaxAttemptsExceededText: 'Max attempts exceeded',
    noAvailabilitiesCustomText: '',
    hideShowMoreButton: false,
};

import React from 'react';
import {
    CardInfoWrapper,
    CardImage,
    CardTitle,
    CardInfoRow
} from './style.css';
import { useContextState } from '../../context';
import { format } from 'date-fns';

const logos = require('./logos.json')
export const practiceDefaultSrc = logos[window?.doctifyBookingWidget?.tenant || 'default']

const PatientInfo = ({ logo }) => {
    const {
        selectedPractice: { name },
        isBookingCompleted,
        config: { theme },
        calendarOptions: { selectedSlot },
        patientForm: { appointmentType },
        appointmentTypes,
    } = useContextState();

    return (
        <div
            className={CardInfoWrapper}
            style={{
                background: isBookingCompleted ? theme.secondaryBackground : theme.primaryBackground
            }}
        >
            <div className={CardImage}>
                <img src={logo || practiceDefaultSrc} alt=""/>
            </div>
            <div style={{ textAlign: 'left' }}>
                <span
                    className={CardTitle}
                    style={{
                        color: theme.textColor,
                        fontSize: theme.fontSize
                    }}
                >
                    {name}
                </span>
                <div
                    className={CardInfoRow}
                    style={{
                        color: theme.textColor,
                        fontSize: theme.fontSizeSm
                    }}
                >
                    <span>
                        {format(new Date((() => {
                            const date = new Date(selectedSlot.start);
                            const userTimezoneOffset = date.getTimezoneOffset() * 60000;
                            return new Date(date.getTime() + userTimezoneOffset)
                        })()), 'E dd LLL HH:mm a')}
                        {' • '}
                        {appointmentTypes.find(type => type.value === appointmentType).label}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PatientInfo;

import emailValidator from 'email-validator';

const validationRules = {
    required: val => ({
        isValid: !!val,
        message: 'This field is required'
    }),
    minLength: (val, len) => ({
        isValid: !val ? true : val.trim().length >= len,
        message: `Min length - ${len}`
    }),
    maxLength: (val, len) => ({
        isValid: !val ? false : val.trim().length <= len,
        message: `Max length - ${len}`
    }),
    email: val => ({
        isValid: !val ? false : emailValidator.validate(val),
        message: 'Email is not valid'
    }),
    gbPostalCode: val => ({
        isValid: !val ? false : /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/g.test(val),
        message: 'Great Britain postal code is not valid'
    }),
};


export const validator = (rulesString, val) => {
    const errors = [];
    const rules = rulesString.split('|');
    rules.map(rule => {
        if (rule.indexOf('-') !== -1) {
            const ruleParts = rule.split('-');
            if (validationRules[ruleParts[0]]) {
                errors.push(validationRules[ruleParts[0]](val, Number(ruleParts[1])))
            }
        } else {
            if (validationRules[rule]) {
                errors.push(validationRules[rule](val))
            }
        }
    });

    return errors.filter(err => !err.isValid).map(err => err.message);
};

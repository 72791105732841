exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".index_ProgressWrapper__3ymb- {\n    text-align: center;\n    margin: 0 auto;\n    position: relative;\n}\n\n.index_ProgressItem__Tqgu1 {\n    width: 40px;\n    height: 8px;\n    border-radius: 25px;\n    display: inline-block;\n    margin-right: 10px;\n}\n\n.index_ProgressItem__Tqgu1:last-child {\n    margin-right: 0;\n}\n\n.index_ProgressChangeStep__2pgSR {\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    background-color: #fffae7;\n    padding: 5px;\n    cursor: pointer;\n    position: absolute;\n    left: 0;\n    top: 50%;\n    -webkit-transform: translate(0, -50%) rotate(180deg);\n        -ms-transform: translate(0, -50%) rotate(180deg);\n            transform: translate(0, -50%) rotate(180deg);\n}\n\n.index_ProgressChangeStep__2pgSR svg {\n    width: 15px;\n    height: 15px;\n}\n\n.index_ProgressItemNext__39GNZ { opacity: 0.2 }\n", "",{"version":3,"sources":["index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;AACtB;;AACA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,oDAA4C;QAA5C,gDAA4C;YAA5C,4CAA4C;AAChD;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA,iCAAoB,aAAa","file":"index.css","sourcesContent":[".ProgressWrapper {\n    text-align: center;\n    margin: 0 auto;\n    position: relative;\n}\n\n.ProgressItem {\n    width: 40px;\n    height: 8px;\n    border-radius: 25px;\n    display: inline-block;\n    margin-right: 10px;\n}\n.ProgressItem:last-child {\n    margin-right: 0;\n}\n\n.ProgressChangeStep {\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    background-color: #fffae7;\n    padding: 5px;\n    cursor: pointer;\n    position: absolute;\n    left: 0;\n    top: 50%;\n    transform: translate(0, -50%) rotate(180deg);\n}\n\n.ProgressChangeStep svg {\n    width: 15px;\n    height: 15px;\n}\n\n.ProgressItemNext { opacity: 0.2 }\n"]}]);

// Exports
exports.locals = {
	"ProgressWrapper": "index_ProgressWrapper__3ymb-",
	"ProgressItem": "index_ProgressItem__Tqgu1",
	"ProgressChangeStep": "index_ProgressChangeStep__2pgSR",
	"ProgressItemNext": "index_ProgressItemNext__39GNZ"
};
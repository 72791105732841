import React from 'react';
import BookingCalendar from './booking-calendar';
import SelectAppointmentType from './select-appointment-type';
import PracticesSelector from './practices-selector';
import Button from './common/button';
import { useContextState } from '../context';
import Spinner from "./common/loader";
import { BookAppointmentTypeTitle } from "./select-appointment-type/styles/index.css";

const SelectSlotStep = ({ action, practicesDropdown, buttonDisabled }) => {
    const { calendarOptions: { selectedSlot }, config: { theme, slotSelectionTitle } } = useContextState();
    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <div>
            <h3
                dangerouslySetInnerHTML={{
                    __html: slotSelectionTitle
                }}
                className={BookAppointmentTypeTitle}
                style={{
                    fontSize: theme.titleFontSize,
                    color: theme.textColor
                }}
            />
            {practicesDropdown ? (
                <div style={{ margin: '20px 0' }}>
                    <PracticesSelector />
                </div>
            ) : null}
            <SelectAppointmentType />
            <BookingCalendar practicesDropdown={practicesDropdown} />
            {isLoading ?
                (<Spinner borderInactive={theme.spinnerSecondaryColor} borderActive={theme.spinnerPrimaryColor} />)
                : (
                    <Button
                        text={'Book Now'}
                        disabled={!selectedSlot || buttonDisabled}
                        onClick={() => {
                            action({ setIsLoading });
                        }}
                    />
                )}
        </div>
    );
};


export default SelectSlotStep;

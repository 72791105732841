/* eslint-disable no-undef */

import { isValid } from 'date-fns';

export function mergeDeep(target, obj) {
    const result = { ...target };
    for (const key in obj) {
        if (typeof obj[key] === 'object') {
            result[key] = mergeDeep(result[key], obj[key]);
        } else {
            Object.assign(result, { [key]: obj[key] });
        }
    }
    return result;
}
export function inputDateMask(val) {
    if (!val || typeof val !== 'string') return '';
    let dateString = val.replace(/[^0-9]/ig, '');
    let changed = false;
    if (([3, 4].includes(dateString.length)) && !changed) {
        changed = true;
        dateString = dateString.replace(/([0-9]{2})([0-9]{1,2})/, '$1 / $2')
    }
    if ((dateString.length > 4 && dateString.length < 9) && !changed) {
        changed = true;
        dateString = dateString = dateString.replace(/([0-9]{2})([0-9]{2})([0-9]{1,4})/, '$1 / $2 / $3')
    }
    if ((dateString.length > 8) && !changed) {
        dateString = dateString.slice(0,8).replace(/([0-9]{2})([0-9]{2})([0-9]{4})/, '$1 / $2 / $3')
    }
    return dateString;
}
export const getDays = () => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
        days.push({ value: i, label: i.toString() });
    }
    return days;
};

export const getMonths = () => {
    return [
        {
            value: 0,
            label: 'January',
        },
        {
            value: 1,
            label: 'February',
        },
        {
            value: 2,
            label: 'March',
        },
        {
            value: 3,
            label: 'April',
        },
        {
            value: 4,
            label: 'May',
        },
        {
            value: 5,
            label: 'June',
        },
        {
            value: 6,
            label: 'July',
        },
        {
            value: 7,
            label: 'August',
        },
        {
            value: 8,
            label: 'September',
        },
        {
            value: 9,
            label: 'October',
        },
        {
            value: 10,
            label: 'November',
        },
        {
            value: 11,
            label: 'December',
        },
    ];
};

export const getYears = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = 1920; i <= currentYear; i++) {
        years.push({ value: i, label: i.toString() });
    }
    return years;
};

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return '';
}

export const getMetrics = (provider) => {
    switch (provider) {
        case 'optegra': {
            // clientId => JS code: ga.getAll()[0].get('clientId');
            // source field => utmcsr
            // medium field => utmcmd
            // campaign field => utmccn
            // term field => utmctr
            // content field => utmcct
            // timestamp - timestamp in the format: "2021-11-29T13:33:12+00:00"
            // acquisition_date - date in the format: "29/11/2021"
            const mapping = {
                utmcsr: 'source',
                utmcmd: 'medium',
                utmccn: 'campaign',
                utmctr: 'term',
                utmcct: 'content',
            }
            return getCookie('__utmzz').split('|').map(r => {
                const splt = r.split('=')
                return ({ [splt[0]]: splt[1] })
            }).reduce((p, c) => ({
                ...p,
                [mapping[Object.keys(c)[0]]]: Object.values(c)[0]
            }), {
                clientId: typeof ga === 'undefined' ? null : ga.getAll()[0].get('clientId')
            })
        }
        default: return {}
    }
}

export const getDate = (date) => {
    const formatDM = (v) => String(v).length === 1 ? `0${v}` : v;

    const dateString = `${date.year}-${formatDM(date.month + 1)}-${formatDM(date.day)}`;
    const dateValue = new Date(date.year, date.month, date.day);
    return isValid(dateValue) && (Object.values(date).filter(val => val !== null).length === 3) ?
        { date: dateValue, formattedDate: dateString }
        : null;
};
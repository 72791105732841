import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { getMetrics } from './utils/helpers';
import { resetState } from "./context/store/actions";
import { flows } from './utils/defaults';

export const FLOWS = flows;

export const init = (config = {}) => {
  let dispatch = () => null;
  const setDispatch = (disp) => {
    dispatch = disp;
  };
  if (!config.practiceId && !(config.flow || config.flow !== flows.DEFAULT)) {
    throw new Error('practiceId is required!');
  }
  if (!config.host) {
    throw new Error('host is required!');
  }

  // specific Optegra workaround!!!
  config.metrics = getMetrics('optegra')

  ReactDOM.render(
    <App config={config} setDispatch={setDispatch} />,
    document.getElementById(config.rootElement || 'booking-widget')
  );

  return {
    forceResetState: () => {
      dispatch(resetState());
    }
  }
};

import logo from '../src/images/nuffield-logo.png';
export default {
  tenant: 'nuffield',
  header: {
    logo: {
      src: logo,
      width: 160
    },
    style: {
      height: 'auto',
      background: 'var(--primary-color)'
    }
  },
  title: {
    text: 'Book your health assessment today',
    style: {
      padding: 60,
      fontSize: '40px'
    }
  },
  tipCards: [{
    title: {
      text: 'Take control of your health'
    },
    tips: [{
      style: {
        color: 'var(--secondary-text-color)'
      },
      icon: 'circle-regular',
      text: 'A health assessment is an in-depth healthcheck. It includes medical and non-invasive tests that give you a clear picture of your health and wellbeing.'
    }, {
      style: {
        color: 'var(--secondary-text-color)'
      },
      icon: 'circle-regular',
      text: 'For Female, 360 and 360+ assessments, a mammogram is available for women aged 40 and over. (A mammogram is a diagnostic test used to look for any problems within the breast and surrounding tissues). Please call us to see if appropriate for you, to check availability and make a booking.'
    }, {
      style: {
        color: 'var(--secondary-text-color)'
      },
      icon: 'circle-regular',
      text: 'If you wish to upgrade your assessment, please call us to arrange this and make a payment.'
    }, {
      style: {
        color: 'var(--secondary-text-color)'
      },
      icon: 'circle-regular',
      text: 'You may be eligible for a discount for additional family member assessments. If you have a family member who would like to use this discount please call us to purchase, check availability and make a booking.'
    }]
  }, {
    title: {
      text: 'Need to talk to someone?'
    },
    tips: [{
      style: {
        color: 'var(--secondary-text-color)',
        lineHeight: '1.7em'
      },
      icon: 'phone-solid',
      text: 'Telephone <br> <a href="tel:0345 230 2040">0345 230 2040</a>'
    }, {
      style: {
        color: 'var(--secondary-text-color)',
        lineHeight: '1.7em'
      },
      icon: 'clock-regular',
      text: 'Opening hours <br> Mon-Fri 8am-6pm'
    }, {
      style: {
        color: 'var(--secondary-text-color)'
      },
      icon: 'circle-regular',
      text: '<b>Please note</b> during this busy period we may have limited availability. Please get in touch if you are unable to find a suitable time.'
    }]
  }]
};
import React from 'react';
import DefaultFlowContainer from './DefautFlowContainer';
import SearchFirstFlowContainer from './SearchFirstFlowContainer';
import { MainContainerWrapper } from '../styles/index.css';
import { useContextState } from '../context';
import { flows } from '../utils/defaults';

const MainContainer = () => {
    const { config: { theme, flow }, isBookingCompleted } = useContextState();
    return (
        <div
            className={MainContainerWrapper}
            style={{
                background: isBookingCompleted ? theme.primaryBackground : theme.secondaryBackground,
                borderColor: theme.borderColor,
                ...(theme.mainContainerBorderRadius ? { borderRadius: theme.mainContainerBorderRadius } : {})
            }}
        >
            {(() => {
                switch (flow) {
                    case flows.DEFAULT: {
                        return <DefaultFlowContainer />
                    }
                    case flows.NUFFIELD: {
                        return <SearchFirstFlowContainer />
                    }
                    default:
                        return <DefaultFlowContainer />
                }
            })()}
        </div>
    );
};


export default MainContainer;

import React from 'react';
import {
    AppointmentTypesWrapper,
    AppointmentTypesControlGroup,
    AppointmentTypesControlGroupInput,
    SelectAppointmentWrapper,
} from './styles/index.css';

import { useContextState, useContextDispatch } from '../../context';
import { changeAppointmentType } from '../../context/store/actions';
import './styles/radio-button.css';

const SelectAppointmentType = () => {
    const { patientForm: { appointmentType }, config: { theme }, appointmentTypes } = useContextState();
    const dispatch = useContextDispatch();

    return (
        <div className={SelectAppointmentWrapper}>
            <span
                style={{
                    fontSize: theme.fontSizeSm,
                    color: theme.textColor
                }}
            >
                Type of appointment:
            </span>
            <div
                className={AppointmentTypesWrapper}
                style={{
                    background: theme.primaryBackground
                }}
            >
                {appointmentTypes.map(appType => (
                    <div key={appType.value} className={AppointmentTypesControlGroup}>
                        <input
                            type="radio"
                            id={appType.value}
                            className={AppointmentTypesControlGroupInput}
                            value={appType.value}
                            checked={appType.value === appointmentType}
                            onChange={(ev) => dispatch(changeAppointmentType(ev.target.value))}
                        />
                        <label
                            htmlFor={appType.value}
                            style={{
                                color: theme.textColor,
                                fontSize: theme.fontSizeSm,
                            }}
                        >
                            {appType.label}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default SelectAppointmentType;

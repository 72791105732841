import React from 'react';
import {
    FormControlFieldWrapperCheckbox,
    FormControlFieldLabel,
    FormControlFieldCheckbox,
} from '../../../styles/form.css';
import { useContextState } from "../../../context";
import {ErrorMessage} from "../../../styles/index.css";

const CheckboxField = ({ checked, onChange, label,  errors = [], forceErr = false }) => {
    const { config: { theme } } = useContextState();
    const [isTouched, setTouched] = React.useState(false);

    return (
        <div style={{ display: 'inlineBlock' }}>
            <div className={FormControlFieldWrapperCheckbox}>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={ev => {
                        if (!isTouched) {
                            setTouched(true);
                        }
                        onChange(ev);
                    }}
                    className={FormControlFieldCheckbox}
                />
                {
                    label ?
                        <label
                            className={FormControlFieldLabel}
                            style={{
                                color: theme.textColor,
                                fontSize: theme.labelFontSize
                            }}
                        >
                            {label}
                        </label>
                        : null
                }
            </div>
            {
                errors.length && (isTouched || forceErr)
                    ?
                    <span
                        className={ErrorMessage}
                        style={{
                            color: theme.errorColor,
                            fontSize: theme.errorFontSize
                        }}
                    >
                            {errors[0]}
                        </span> :
                    null
            }
        </div>
    );
};
export default CheckboxField;

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".style_CardInfoWrapper__2oHdi {\n    width: calc(100% - 30px);\n    padding: 15px;\n    display: -webkit-flex;\n    display: -moz-box;\n    display: -ms-flexbox;\n    display: flex;\n    border-radius: 3px;\n}\n\n.style_CardImage__1NwtN {\n    border: 1px solid;\n    border-radius: 4px;\n    margin-right: 15px;\n    width: 50px;\n    height: 50px;\n}\n\n.style_CardImage__1NwtN img {\n    width: 100%;\n    height: 100%;\n    -o-object-fit: cover;\n       object-fit: cover;\n}\n\n.style_CardInfoWrapper__2oHdi .style_CardInfoRow__l5nwP {\n    margin-bottom: 10px;\n    text-align: left;\n    font-size: 16px;\n}\n\n.style_CardTitle__51wBE {\n    font-weight: bold;\n    margin-bottom: 10px;\n    display: block;\n}\n", "",{"version":3,"sources":["style.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,aAAa;IACb,qBAAa;IAAb,iBAAa;IAAb,oBAAa;IAAb,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oBAAiB;OAAjB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;AAClB","file":"style.css","sourcesContent":[".CardInfoWrapper {\n    width: calc(100% - 30px);\n    padding: 15px;\n    display: flex;\n    border-radius: 3px;\n}\n\n.CardImage {\n    border: 1px solid;\n    border-radius: 4px;\n    margin-right: 15px;\n    width: 50px;\n    height: 50px;\n}\n\n.CardImage img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.CardInfoWrapper .CardInfoRow {\n    margin-bottom: 10px;\n    text-align: left;\n    font-size: 16px;\n}\n\n.CardTitle {\n    font-weight: bold;\n    margin-bottom: 10px;\n    display: block;\n}\n"]}]);

// Exports
exports.locals = {
	"CardInfoWrapper": "style_CardInfoWrapper__2oHdi",
	"CardImage": "style_CardImage__1NwtN",
	"CardInfoRow": "style_CardInfoRow__l5nwP",
	"CardTitle": "style_CardTitle__51wBE"
};
import { tenant } from './constants'; // eslint-disable-next-line no-unused-expressions

export default (function () {
  switch (tenant) {
    case 'nuffield':
      {
        // eslint-disable-next-line no-unused-expressions
        import('./globalStyles/index-nuffield.css');
        return require('./config/nuffield').default;
      }

    default:
      return {};
  }
})();
import React from 'react';
import {useContextDispatch, useContextState} from "../../context";
import {addDays, format, isToday, subDays, isSameDay} from "date-fns";
import Spinner from "../common/loader";
import {
    DatePickerCarouselArrow,
    DatePickerCarouselArrowNext,
    DatePickerCarouselArrowPrev,
    DatePickerCarouselItem,
    BookingCalendarWrapper,
    BookingCalendarColumn,
    BookingCalendarColumnsWrapper,
    BookingCalendarColumnSlot,
    DatePickerCarouselItemDate,
    BookingCalendarShowMore,
    BookingCalendarSlotsWrapper,
    DatePickerCarouselItemWrapper
} from "./styles/index.css";
import {changeCalendarCurrentDate, selectSlot, setColumnsToShow} from "../../context/store/actions";
import {ReactComponent as Arrow} from "../../assets/icons/arrow.svg";
import { getSlots } from "../../api";
import Button from "../common/button";

const formatTime = (time) => {
    const [h, m] = time?.split('T')?.[1]?.split(':')
    return `${h}:${m}`
}

export const SlotBtn = ({
    theme = {},
    slotItem = {},
    selectedSlot = {},
    onSelectSlot = () => null,
    setSlotToScroll = () => null,
    slotToScroll = null
}) => {
    const [hovered, setHover] = React.useState(false);
    React.useEffect(() => {
        if (slotToScroll === slotItem.id) {
            document.getElementById(`slot-${slotToScroll}`).scrollIntoView({ behavior: 'smooth' })
            setSlotToScroll(null);
        }
    }, []);
    return (
        <button
            id={`slot-${slotItem.id}`}
            style={{
                fontSize: theme.fontSizeSm,
                color: ((selectedSlot && selectedSlot.id === slotItem.id) || hovered) ? theme.btnTextColor : theme.textColor,
                backgroundColor: ((selectedSlot && selectedSlot.id === slotItem.id) || hovered) ? theme.activeSlotColor : theme.slotColor,
                border: ((selectedSlot && selectedSlot.id === slotItem.id) || hovered) ? theme.activeSlotBorder : theme.slotBorder,
                ...(theme.slotMargin ? { margin: theme.slotMargin } : {})
            }}
            className={BookingCalendarColumnSlot}
            onClick={() => onSelectSlot(slotItem)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {formatTime(slotItem.start)}
        </button>
    );
};

export const CalendarColumn = ({
   slots = [],
   onSelectSlot = () => null,
   theme,
   showSlotsCount,
   selectedSlot,
   setSlotToScroll = () => null,
   slotToScroll = null
}) => {
    const slotsToShow = slots.slice(0, showSlotsCount);
    return (
        <div className={BookingCalendarColumn}>
            {
                slotsToShow.map((slotItem, idx) => (
                    <SlotBtn
                        key={idx}
                        selectedSlot={selectedSlot}
                        slotItem={slotItem}
                        onSelectSlot={onSelectSlot}
                        theme={theme}
                        setSlotToScroll={setSlotToScroll}
                        slotToScroll={slotToScroll}
                    />
                ))
            }
        </div>
    );
};

const BookingCalendar = ({ practicesDropdown = false }) => {
    const {
        calendarOptions: { startDate, columnsToShow, selectedSlot },
        config: { theme, host, practiceId: defaultPracticeId, widgetParams, noAvailabilitiesCustomText, slotsEndDate, hideShowMoreButton },
        patientForm: { appointmentType },
        selectedPractice,
    } = useContextState();
    const [practiceId, setPracticeId] = React.useState(defaultPracticeId)
    const [currentWeekDays, setCurrentWeekDays] = React.useState([...Array(columnsToShow + 1)].map((col, idx) => addDays(startDate, idx)));
    const [slots, setSlots] = React.useState({});
    const [slotToScroll, setSlotToScroll] = React.useState(null);
    const [showSlotsCount, setShowSlotsCount] = React.useState(hideShowMoreButton ? 1000 : 7);
    const [maxSlotsNumber, setMaxSlotsNumber] = React.useState({ value: 0, key: null });
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useContextDispatch();

    React.useEffect(() => {
        if (practicesDropdown) {
            setPracticeId(selectedPractice?.id)
        }
    }, [practicesDropdown, selectedPractice])

    React.useEffect(() => {
        setCurrentWeekDays(
            [...Array(columnsToShow + 1)].map((col, idx) => addDays(startDate, idx))
        );
        window.onresize = () => {
            if (window.innerWidth <= 580 && columnsToShow > 4) {
                dispatch(setColumnsToShow(4))
            }
            if (window.innerWidth > 580 && columnsToShow < 6) {
                dispatch(setColumnsToShow(6))
            }
        }
    }, [columnsToShow, startDate]);
    React.useEffect(() => {
        const slotsData = {};
        let maxSlotsLength = { value: 0, key: null };
        const weekDays = [...Array(columnsToShow + 1)].map((col, idx) => addDays(startDate, idx));
        if (weekDays.length && !isLoading && practiceId) {
            setMaxSlotsNumber({ value: 0, key: null });
            setIsLoading(true);
            getSlots(
                host,
                {
                    type: appointmentType,
                    practiceId,
                    date: format(startDate, 'yyyy-MM-dd'),
                },
                widgetParams,
                )
                .then(practiceSlots => {
                    weekDays.map(weekDay => {
                        const daySlots = practiceSlots.filter(slot => isSameDay(new Date(slot.start), weekDay));
                        if (daySlots.length > maxSlotsLength.value) {
                            maxSlotsLength.value = daySlots.length;
                            maxSlotsLength.key = weekDay;
                        }
                        slotsData[weekDay] = {
                            slots: daySlots,
                            count: daySlots.length
                        };
                    });
                    setSlots(slotsData);
                    setMaxSlotsNumber(maxSlotsLength);
                    setIsLoading(false);
                })
                .catch(() => {
                    setSlots({});
                    setIsLoading(false);
            });
        }
    }, [appointmentType, startDate, practiceId]);
    return (
        <>
            <div className={BookingCalendarWrapper}>
                {
                    !isToday(startDate) ?
                        (
                            <div
                                style={{ background: theme.primaryBackground }}
                                className={`${DatePickerCarouselArrow} ${DatePickerCarouselArrowPrev}`}
                                onClick={() => dispatch(changeCalendarCurrentDate(subDays(startDate, columnsToShow)))}
                            >
                                <Arrow />
                            </div>)
                        :
                        null
                }
                <div className={BookingCalendarColumnsWrapper}>
                    <div className={DatePickerCarouselItemWrapper}>
                        {currentWeekDays.map((dayOfWeek, idx) => (
                            <div className={DatePickerCarouselItem} key={idx}>
                                        <span
                                            style={{
                                                fontSize: theme.fontSizeSm,
                                                color: theme.textColor
                                            }}
                                        >
                                            {format(dayOfWeek, 'iii')}
                                        </span>
                                <span
                                    className={DatePickerCarouselItemDate}
                                    style={{
                                        fontSize: theme.fontSizeSm,
                                        color: theme.textColor
                                    }}
                                >
                                            {format(dayOfWeek, 'dd MMM')}
                                        </span>
                            </div>
                        ))}
                    </div>
                    {maxSlotsNumber.value ? (
                        <div className={BookingCalendarSlotsWrapper}>
                            {currentWeekDays.map((dayOfWeek, idx) => {
                                return (
                                    <CalendarColumn
                                        key={idx}
                                        slots={slots[dayOfWeek] && slots[dayOfWeek].count ? slots[dayOfWeek].slots : []}
                                        date={dayOfWeek}
                                        onSelectSlot={slot => dispatch(selectSlot(slot))}
                                        theme={theme}
                                        showSlotsCount={showSlotsCount}
                                        selectedSlot={selectedSlot}
                                        setSlotToScroll={setSlotToScroll}
                                        slotToScroll={slotToScroll}
                                    />
                                )
                            })}
                        </div>
                    ) : null}
                </div>
                {
                    !slotsEndDate || new Date(slotsEndDate) >= addDays(startDate, columnsToShow) ? (
                        <div
                            style={{ background: theme.primaryBackground }}
                            className={`${DatePickerCarouselArrow} ${DatePickerCarouselArrowNext}`}
                            onClick={() => {
                                dispatch(changeCalendarCurrentDate(addDays(startDate, columnsToShow)))
                            }}
                        >
                            <Arrow />
                        </div>
                    ) : null
                }
            </div>
            {
                !practiceId ? (
                    <div
                        style={{
                            color: theme.errorColor,
                            margin: '40px',
                            padding: '20px',
                            background: theme.errorBackground,
                        }}
                        dangerouslySetInnerHTML={{ __html: noAvailabilitiesCustomText }}
                    />
                ) : null
            }
            {
                !maxSlotsNumber.value && practiceId && !isLoading ?
                    (
                        <div
                            style={{
                                color: theme.textColor,
                                fontSize: theme.fontSize,
                                textAlign: 'center',
                                margin: '15px auto'
                            }}
                            dangerouslySetInnerHTML={{
                                __html: noAvailabilitiesCustomText || 'There are no available slots.'
                            }}
                        >
                        </div>
                    ) :
                    null
            }
            {isLoading ?
                (<Spinner borderInactive={theme.spinnerSecondaryColor} borderActive={theme.spinnerPrimaryColor} />)
                : null}
            {maxSlotsNumber.value > showSlotsCount ? (
                <Button
                    additionalClass={BookingCalendarShowMore}
                    styles={{ backgroundColor: theme.showMoreBg }}
                    onClick={() => {
                        setShowSlotsCount(showSlotsCount + 7);
                        if (maxSlotsNumber.key) {
                            if (slots[maxSlotsNumber.key].value < (showSlotsCount + 7)) {
                                const { id } = slots[maxSlotsNumber.key].slots[slots[maxSlotsNumber.key].slots.length - 7];
                                setSlotToScroll(id);
                            } else {
                                const { id } = slots[maxSlotsNumber.key].slots[showSlotsCount];
                                setSlotToScroll(id);
                            }
                        }
                    }}
                    text={'Show more'}
                />
            ) : null }
        </>
    )
};

export default BookingCalendar;

import React from 'react';
import { rootReducer, initialState } from "./store/reducer";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const ContextProvider = ({ children, defaultState = {}, config, setDispatch }) => {
    const [state, dispatch] = React.useReducer(rootReducer, { ...initialState, ...defaultState, config: config });
    setDispatch(dispatch);
    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

function useContextState() {
    const context = React.useContext(StateContext);
    if (!context) {
       return {}
    }

    return context
};


function useContextDispatch() {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        return {}
    }
    return context
}

export {
    StateContext,
    DispatchContext,
    ContextProvider,
    useContextState,
    useContextDispatch
}

import React from 'react'
import { useContextDispatch, useContextState } from '../../context';
import { setPractice } from '../../context/store/actions';
import Dropdown from '../common/dropdown-field';
import { practiceDefaultSrc } from '../practice-card-info';
import { CardImage } from '../practice-card-info/style.css';

export default () => {
    const { practices, selectedPractice } = useContextState();
    const dispatch = useContextDispatch();

    React.useEffect(() => {
        if (practices.length) {
            dispatch(setPractice(practices[0]))
        }
    }, [practices])

    return (
        <Dropdown
            showArrow={true}
            options={practices.map(p => ({
                value: p.id,
                label: p.name,
                data: p,
            }))}
            value={(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img className={CardImage} src={selectedPractice?.logo || practiceDefaultSrc} alt={''}/>
                    <span>{selectedPractice?.name}</span>
                </div>
            )}
            onChange={({ data }) => dispatch(setPractice(data))}
        />
    )
}
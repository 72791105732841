import React from 'react';
import {
    FormControlFieldWrapper,
    FormControlFieldLabel,
    FormControlFieldComponent
} from '../../../styles/form.css';
import { StateContext } from "../../../context";
import IntlTelInput from 'react-intl-tel-input';
import {ErrorMessage} from "../../../styles/index.css";


class PhoneInput extends React.Component {
    static contextType = StateContext;
    constructor(props) {
        super(props);
        this.phoneRef = null;
        this.state = {
            isTouched: false
        }
    }

    getPhoneValue = () => {
      if (this.phoneRef) {
          const phone = this.phoneRef.state.dialCode + this.phoneRef.state.value
          return phone.replace(/( |-|[^(0-9)])/g, '');
      } else {
          return ''
      }
    };

    render() {
        const { config: { theme } } = this.context;
        const { isTouched } = this.state;
        const { onChange = () => null, label, errors = [], forceErr } = this.props;
        return (
            <div className={FormControlFieldWrapper}>
                {
                    label ?
                        (
                            <label
                                className={FormControlFieldLabel}
                                style={{
                                    color: theme.textColor,
                                    fontSize: theme.labelFontSize
                                }}
                            >
                                {label}
                            </label>
                        )
                        : null
                }
                <IntlTelInput
                    ref={ref => this.phoneRef = ref}
                    onPhoneNumberChange={isValid => {
                        this.setState({ isTouched: true }, () => {
                            onChange({ value: this.getPhoneValue(), isValid })
                        });
                    }}
                    telInputProps={{
                        style: {
                            color: theme.textColor,
                            borderColor: theme.borderColor,
                            fontSize: theme.inputFontSize
                        }
                    }}
                    style={{
                        width: '100%'
                    }}
                    inputClassName={FormControlFieldComponent}
                    defaultCountry={this.props.defaultCountry}
                    initialCountry={'uk'}
                    separateDialCode
                    nationalMode={false}
                    format={false}
                />
                {
                    errors.length && (isTouched || forceErr)
                        ?
                        <span
                            className={ErrorMessage}
                            style={{
                                color: theme.errorColor,
                                fontSize: theme.errorFontSize
                            }}
                        >
                        {errors[0]}
                    </span> :
                        null
                }
            </div>
        );
    }
}

export default PhoneInput;

import React from 'react';
import {
    FormControlFieldWrapper,
    FormControlFieldLabel,
    FormControlTextareaComponent
} from '../../../styles/form.css';
import {ErrorMessage} from "../../../styles/index.css";
import { useContextState } from "../../../context";

const TextareaField = ({
   value = '',
   onChange = () => null,
   label = '',
   errors = [],
   forceErr = false
}) => {
    const { config: { theme } } = useContextState();
    const [isTouched, setTouched] = React.useState(false);
    return (
        <div className={FormControlFieldWrapper}>
            {
                label ?
                    <label
                        className={FormControlFieldLabel}
                        style={{
                            color: theme.textColor,
                            fontSize: theme.labelFontSize
                        }}
                    >
                        {label}
                    </label>
                    : null
            }
            <textarea
                value={value}
                onChange={(ev) => {
                    if (!isTouched) {
                        setTouched(true)
                    }
                    onChange(ev)
                }}
                className={FormControlTextareaComponent}
                style={{
                    color: theme.textColor,
                    borderColor: theme.borderColor,
                    fontSize: theme.inputFontSize
                }}
            />
            {
                errors.length && (isTouched || forceErr) ?
                    <span
                        className={ErrorMessage}
                        style={{
                            color: theme.errorColor,
                            fontSize: theme.errorFontSize
                        }}
                    >
                        {errors[0]}
                    </span>
                    : null
            }
        </div>
    );
};

export default TextareaField;

import React from 'react';
import {
    FormControlFieldWrapper,
    FormControlFieldLabel,
    FormControlFieldComponent
} from '../../../styles/form.css';
import { ErrorMessage } from '../../../styles/index.css';
import { useContextState } from "../../../context";

const Index = ({
    label = '',
    value = '',
    type = 'text',
    placeholder = '',
    onChange = () => null,
    errors = [],
    forceErr = false,
    readOnly = false
}) => {
    const [isTouched, setTouched] = React.useState(false);
    const { config: { theme } } = useContextState();

    return (
        <div className={FormControlFieldWrapper}>
            {
                label ?
                    (
                        <label
                            className={FormControlFieldLabel}
                            style={{
                                color: theme.textColor,
                                fontSize: theme.labelFontSize
                            }}
                        >
                            {label}
                        </label>
                    )
                    : null
            }
            <input
                className={FormControlFieldComponent}
                type={type}
                value={value}
                placeholder={placeholder}
                style={{
                    color: theme.textColor,
                    borderColor: theme.borderColor,
                    fontSize: theme.inputFontSize
                }}
                readOnly={readOnly}
                onChange={ev => {
                    if (!isTouched) {
                        setTouched(true)
                    }
                    onChange(ev)
                }}
            />
            {
                errors.length && (isTouched || forceErr)
                    ?
                    <span
                        className={ErrorMessage}
                        style={{
                            color: theme.errorColor,
                            fontSize: theme.errorFontSize
                        }}
                    >
                        {errors[0]}
                    </span> :
                    null
            }
        </div>
    );
};


export default Index;

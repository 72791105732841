import React from 'react';
import { ReactComponent as DoctifyLogo } from '../../../assets/icons/doctify-logo.svg';
import { ReactComponent as PoweredBySvg } from '../../../assets/icons/powered_by.svg'
import { PoweredWrapper } from './index.css';

const PoweredBy = () => {
    return (
        <div
            className={PoweredWrapper}
        >
            <PoweredBySvg />
            <DoctifyLogo style={{ marginLeft: 5, width: 85 }} />
        </div>
    );
};

export default PoweredBy;

import _slicedToArray from "/home/s/Repositories/Doctify/booking-calendar-widget/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
export default (function () {
  var _window$location$path = window.location.pathname.split('/'),
      _window$location$path2 = _slicedToArray(_window$location$path, 4),
      patientId = _window$location$path2[1],
      activityId = _window$location$path2[2],
      payorId = _window$location$path2[3];

  if (!(patientId && activityId && payorId)) {// throw new Error('404')
  }

  return {
    patientId: patientId,
    activityId: activityId,
    payorId: payorId
  };
});
const baseURl = 'https://integrations-development.doctify.com/'

const defaultFetchSettings = {
    method: 'GET',
    cache: 'no-cache',
}

const objectToHeaders = (obj) => {
    const headers = {}
    for (let [key, value] of Object.entries(obj)) {
        headers[key] = value
    }
    return headers
}

export const getSlots = async (host, { type, practiceId, date = new Date() }, widgetParams = {}) => {
    try {
        return fetch(`${baseURl}${host}/slots?appointmentType=${type}&practiceId=${practiceId}&dateFrom=${date}`, {
            ...defaultFetchSettings,
            headers: objectToHeaders(widgetParams),
        })
            .then(res => res.json())
            .then(data => data);
    } catch (err) {
        throw err;
    }
};

export const createAppointment = async (host, data, widgetParams = {}) => {
    try {
        return fetch(`${baseURl}${host}/appointments`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                ...objectToHeaders(widgetParams),
    }
        }).then(res => res.json())
            .catch(err => {
                throw err;
            })
    } catch (err) {
        throw err;
    }
};

export const getPracticeInfo = async (host, practiceId, widgetParams = {}) => {
    try {
        return fetch(`${baseURl}${host}/practices/${practiceId}`, {
            ...defaultFetchSettings,
            headers: objectToHeaders(widgetParams),
        })
            .then(res => res.json());
    } catch (err) {
        throw err;
    }
};

export const getPatientInfo = async (host, patientInputs, widgetParams = {}) => {
    try {
        const url = patientInputs.id
            ? `${baseURl}${host}/patients/${patientInputs.id}`
            : `${baseURl}${host}/patients`

        return fetch(url, {
            ...defaultFetchSettings,
            headers: objectToHeaders(widgetParams),
        })
            .then(res => res.json());
    } catch (err) {
        throw err;
    }
};

export const getPractices = async (host, inputs, widgetParams = {}) => {
    try {
        const q = Object.keys(inputs).map(k => `${k}=${inputs[k]}`).join('&')
        return fetch(`${baseURl}${host}/practices?${q}`, {
            ...defaultFetchSettings,
            headers: objectToHeaders(widgetParams),
        })
            .then(res => res.json());
    } catch (err) {
        throw err;
    }
};

export const getAppointmentTypes = async (host, inputs, widgetParams = {}) => {
    try {
        return fetch(`${baseURl}${host}/appointment-types`, {
            ...defaultFetchSettings,
            headers: objectToHeaders(widgetParams),
        })
            .then(res => res.json());
    } catch (err) {
        throw err;
    }
};

export const authPatient = async (host, data, widgetParams = {}, callback = () => {}) => {
    try {
        return fetch(`${baseURl}${host}/auth`, {
            ...defaultFetchSettings,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                ...objectToHeaders(widgetParams),
            },
        })
            .then(async (res) => {
                const result = await res.json();
                callback(result)
            });
    } catch (err) {
        callback(false)
        throw err;
    }
};
import React from 'react';
import { ButtonComponent } from './style.css';
import { useContextState } from "../../../context";


const Button = (
    {
        text = '',
        onClick = () => null,
        disabled = false,
        styles = {},
        additionalClass = '',
        placeholder = '',
    }) => {
    const { config: { theme } } = useContextState();
    const [hovered, setHover] = React.useState(false);
    const defaultBtnStyle = {
        color: theme.btnTextColor,
        backgroundColor: theme.btnBackground,
        fontSize: theme.buttonFontSize,
    };
    if (hovered) {
        defaultBtnStyle.backgroundColor = theme.btnHoverBackground;
    }
    return (
        <button
            className={`${ButtonComponent} ${additionalClass}`}
            onClick={onClick}
            disabled={disabled}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            placeholder={placeholder}
            style={{...defaultBtnStyle, ...styles}}
        >
            {text}
        </button>
    );
};

export default Button;

import {
    GO_TO_NEXT_STEP,
    SET_PATIENT_FORM_FIELD_VALUE,
    RESET_PATIENT_FORM,
    RESET_STATE,
    CHANGE_CALENDAR_CURRENT_DATE,
    CHANGE_APPOINTMENT_TYPE,
    SELECT_SLOT,
    GO_BACK,
    BOOKING_COMPLETED,
    SET_COLUMNS_TO_SHOW,
    SET_PRACTICE,
    SET_PATIENT,
    SET_APPOINTMENT_TYPES,
    AUTH_ATTEMPT_TRIGGER,
    SET_PRACTICES,
} from './actions';
import { appointmentTypes } from '../../utils/defaults'
import { getCookie } from '../../utils/cookies'

const initialState = {
    currentStep: 0,
    selectedSlot: null,
    selectedPractice: null,
    calendarOptions: {
        selectedSlot: null,
        startDate: new Date(),
        columnsToShow: window.innerWidth <= 580 ? 4 : 6,
    },
    patientAuth: {
        id: null,
    },
    practices: [],
    patientForm: {
        firstName: '',
        lastName: '',
        dayOfBirth: {
            day: null,
            month: null,
            year: null
        },
        reason: '',
        email: '',
        phoneNumber: '',
        appointmentType: appointmentTypes[0].value,
        zipCode: '',
        zipCodeSearch: '',
    },
    isBookingCompleted: false,
    authAttempts: getCookie('doctify-booking-widget-auth-attempts') || 0,
    appointmentTypes,
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PRACTICE: {
            return {
                ...state,
                selectedPractice: action.payload
            }
        }
        case SET_COLUMNS_TO_SHOW: {
            return {
                ...state,
                calendarOptions: {
                    ...state.calendarOptions,
                    columnsToShow: action.payload
                }
            }
        }
        case GO_TO_NEXT_STEP: {
            return { ...state, currentStep: state.currentStep + 1 }
        }
        case GO_BACK: {
            return { ...state, currentStep: state.currentStep - 1 }
        }
        case SET_PATIENT_FORM_FIELD_VALUE: {
            const { key, value } = action.payload;
            const { patientForm } = state;
            if (patientForm.hasOwnProperty(key)) {
                return { ...state, patientForm: { ...patientForm, [key]: value }}
            }
            return state;
        }
        case RESET_PATIENT_FORM: {
            return { ...state, patientForm: { ...initialState.patientForm }}
        }
        case RESET_STATE: {
            return {
                ...initialState,
                config: state.config,
                selectedPractice: state.selectedPractice,
                calendarOptions: {
                    ...initialState.calendarOptions,
                    columnsToShow: state.calendarOptions.columnsToShow
                }
            }
        }
        case CHANGE_CALENDAR_CURRENT_DATE: {
            return { ...state, calendarOptions: { ...state.calendarOptions, startDate: action.payload } }
        }
        case CHANGE_APPOINTMENT_TYPE: {
            return { ...state, patientForm: { ...state.patientForm, appointmentType: action.payload } }
        }
        case SELECT_SLOT: {
            return { ...state, calendarOptions: { ...state.calendarOptions, selectedSlot: action.payload } }
        }
        case BOOKING_COMPLETED: {
            return { ...state, isBookingCompleted: true }
        }
        case SET_PATIENT: {
            return { ...state, patientAuth: { ...state.patientAuth, ...action.payload } }
        }
        case AUTH_ATTEMPT_TRIGGER: {
            return { ...state, authAttempts: ++state.authAttempts }
        }
        case SET_PRACTICES: {
            return { ...state, practices: action.payload }
        }
        case SET_APPOINTMENT_TYPES: {
            return {
                ...state,
                appointmentTypes: action.payload,
                patientForm: {
                    ...state.patientForm,
                    appointmentType: (action.payload)?.[0]?.value
                }
            }
        }
        default:
            return state;
    }
}

export {
    rootReducer,
    initialState
}

import React from 'react';
import InputField from './../common/input-field';
import Button from './../common/button';
import { useContextState, useContextDispatch } from '../../context';
import { setPatientFormValue } from '../../context/store/actions';
import { validator } from '../../utils/validator';
import { FormTitle, FormContainerWrapper } from '../../styles/form.css';
import PracticeInfo from '../practice-card-info';
import { flows } from '../../utils/defaults';

const validateValues = (firstName, lastName, email) => {
    const firstNameErr = validator('required|minLength-2', firstName);
    const lastNameErr = validator('required|minLength-2', lastName);
    const emailErr = validator('required|email', email);
    return {
        firstNameErr,
        lastNameErr,
        emailErr,
        isValid: !firstNameErr.length && !lastNameErr.length && !emailErr.length
    }
};

const NameForm = ({ action, buttonDisabled = false }) => {
    const { patientForm: { firstName, lastName, email }, config: { theme, flow, patientFormTitle } } = useContextState();
    const dispatch = useContextDispatch();
    const [showError, setShowErr] = React.useState(false);
    const { isValid, firstNameErr, lastNameErr, emailErr } = validateValues(firstName, lastName, email);

    return (
        <div className={FormContainerWrapper}>
            <h3
                className={FormTitle}
                style={{
                    fontSize: theme.titleFontSize,
                    color: theme.textColor
                }}
            >
                {patientFormTitle}
            </h3>
            {flow === flows.NUFFIELD
                ? null
                : <PracticeInfo />
            }
            <InputField
                label={'First name'}
                value={firstName}
                onChange={ev => dispatch(setPatientFormValue({ key: 'firstName', value: ev.target.value }))}
                errors={firstNameErr}
                forceErr={showError}
            />
            <InputField
                label={'Last name'}
                value={lastName}
                onChange={ev => dispatch(setPatientFormValue({ key: 'lastName', value: ev.target.value }))}
                errors={lastNameErr}
                forceErr={showError}
            />
            <InputField
                label={'Email'}
                value={email}
                onChange={ev => dispatch(setPatientFormValue({ key: 'email', value: ev.target.value }))}
                errors={emailErr}
                forceErr={showError}
            />
            <Button disabled={buttonDisabled} text="Next" onClick={() => {
                setShowErr(!isValid);
                if (isValid) {
                    action();
                }
            }} />
        </div>
    );
};

export default NameForm;

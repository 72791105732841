import React from 'react';
import { useContextState, useContextDispatch } from "../context";
import { ReactComponent as Calendar } from '../assets/icons/calendar.svg';
import { BookingCompletedWrapper, BookingTitle, BookingCompletedNewBtn, BookingText } from '../styles/index.css';
import PatientInfo from "./practice-card-info";
import { resetState } from "../context/store/actions";

const BookingCompleted = () => {
    const { config: { theme, hooks: { onAddNewBooking }, bookingCompletedDescription } } = useContextState();
    // const dispatch = useContextDispatch();
    return (
        <div className={BookingCompletedWrapper}>
            <Calendar />
            <h3 className={BookingTitle}>Booking confirmed</h3>
            <p
                style={{ fontSize: theme.fontSize, color: theme.textColor }}
                className={BookingText}
                dangerouslySetInnerHTML={{ __html: bookingCompletedDescription }}
            />
            <PatientInfo />
            {/*<button*/}
            {/*    className={BookingCompletedNewBtn}*/}
            {/*    style={{*/}
            {/*        background: theme.secondaryBackground,*/}
            {/*        fontSize: theme.fontSize,*/}
            {/*        color: theme.textColor*/}
            {/*    }}*/}
            {/*    onClick={() => {*/}
            {/*        dispatch(resetState());*/}
            {/*        onAddNewBooking();*/}
            {/*    }}*/}
            {/*>*/}
            {/*    + Add to my calendar*/}
            {/*</button>*/}
        </div>
    );
};

export default BookingCompleted;

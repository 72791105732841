import React from 'react';
import { useContextState } from "../context";
import { BookingCompletedWrapper, BookingTitle, BookingText } from '../styles/index.css';
import { practiceDefaultSrc } from "./practice-card-info"
import {
    CardInfoWrapper,
    CardImage,
    CardTitle,
    CardInfoRow
} from './practice-card-info/style.css';
import { format } from "date-fns";

const BookingCompleted = () => {
    let { config: { theme, bookingCompletedDescription },
        selectedPractice,
        isBookingCompleted,
        calendarOptions: { selectedSlot },
        patientForm: { appointmentType },
        appointmentTypes,
    } = useContextState();
    // const dispatch = useContextDispatch();

    const slotType = appointmentTypes.find(type => type.value === appointmentType).label

    return (
        <div className={BookingCompletedWrapper}>
            <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="10" width="49" height="42.5283" rx="6"/>
                <path d="M32.0979 20.2717C31.9767 20.1958 31.8416 20.1446 31.7005 20.121C31.5594 20.0974 31.4151 20.1019 31.2757 20.1342C31.1364 20.1665 31.0047 20.2261 30.8884 20.3093C30.7721 20.3926 30.6734 20.498 30.5979 20.6196L23.174 32.5054L19.2447 28.5815C19.0367 28.4034 18.7692 28.3104 18.4957 28.321C18.2221 28.3315 17.9626 28.4449 17.769 28.6385C17.5755 28.8321 17.4621 29.0916 17.4515 29.3651C17.4409 29.6387 17.534 29.9062 17.7121 30.1141L22.6034 35.0054C22.8063 35.2095 23.0818 35.3249 23.3697 35.3261H23.4947C23.6573 35.3074 23.8136 35.2523 23.9519 35.1648C24.0903 35.0772 24.2071 34.9596 24.2936 34.8206L32.4457 21.7772C32.5225 21.6557 32.5744 21.5202 32.5985 21.3786C32.6225 21.2369 32.6182 21.0919 32.5859 20.9519C32.5535 20.8119 32.4938 20.6797 32.41 20.5629C32.3262 20.4462 32.2202 20.3472 32.0979 20.2717Z" fill="var(--primary-color)"/>
                <path d="M46.7391 5.43478H39.6739V1.08696C39.6739 0.798678 39.5594 0.522206 39.3556 0.318362C39.1517 0.114518 38.8752 0 38.587 0C38.2987 0 38.0222 0.114518 37.8184 0.318362C37.6145 0.522206 37.5 0.798678 37.5 1.08696V5.43478H12.5V1.08696C12.5 0.798678 12.3855 0.522206 12.1816 0.318362C11.9778 0.114518 11.7013 0 11.413 0C11.1248 0 10.8483 0.114518 10.6444 0.318362C10.4406 0.522206 10.3261 0.798678 10.3261 1.08696V5.43478H3.26087C2.39603 5.43478 1.56662 5.77834 0.955087 6.38987C0.343555 7.0014 0 7.83082 0 8.69565V46.7391C0 47.604 0.343555 48.4334 0.955087 49.0449C1.56662 49.6564 2.39603 50 3.26087 50H46.7391C47.604 50 48.4334 49.6564 49.0449 49.0449C49.6564 48.4334 50 47.604 50 46.7391V8.69565C50 7.83082 49.6564 7.0014 49.0449 6.38987C48.4334 5.77834 47.604 5.43478 46.7391 5.43478ZM47.8261 46.7391C47.8261 47.0274 47.7116 47.3039 47.5077 47.5077C47.3039 47.7116 47.0274 47.8261 46.7391 47.8261H3.26087C2.97259 47.8261 2.69612 47.7116 2.49228 47.5077C2.28843 47.3039 2.17391 47.0274 2.17391 46.7391V8.69565C2.17391 8.40737 2.28843 8.1309 2.49228 7.92706C2.69612 7.72321 2.97259 7.6087 3.26087 7.6087H10.3261V11.413C10.3261 11.7013 10.4406 11.9778 10.6444 12.1816C10.8483 12.3855 11.1248 12.5 11.413 12.5C11.7013 12.5 11.9778 12.3855 12.1816 12.1816C12.3855 11.9778 12.5 11.7013 12.5 11.413V7.6087H37.5V11.413C37.5 11.7013 37.6145 11.9778 37.8184 12.1816C38.0222 12.3855 38.2987 12.5 38.587 12.5C38.8752 12.5 39.1517 12.3855 39.3556 12.1816C39.5594 11.9778 39.6739 11.7013 39.6739 11.413V7.6087H46.7391C47.0274 7.6087 47.3039 7.72321 47.5077 7.92706C47.7116 8.1309 47.8261 8.40737 47.8261 8.69565V46.7391Z" fill="var(--primary-color)"/>
            </svg>
            <h3 style={{ marginTop: 10 }} className={BookingTitle}>Booking completion</h3>
            <div
                className={CardInfoWrapper}
                style={{
                    alignContent: 'center',
                    background: isBookingCompleted ? theme.secondaryBackground : theme.primaryBackground
                }}
            >
                <div className={CardImage} style={{ width: 70, height: 70 }}>
                    <img src={practiceDefaultSrc} alt=""/>
                </div>
                <div style={{ textAlign: 'left' }}>
                <span
                    className={CardTitle}
                    style={{
                        color: theme.textColor,
                        fontSize: 18,
                        fontWeight: 100,
                    }}
                >
                    {selectedPractice?.name}
                </span>
                    <div
                        className={CardInfoRow}
                        style={{
                            color: theme.textColor,
                            marginBottom: 0,
                            fontWeight: 'bold',
                        }}
                    >
                    <span>
                        {format(new Date((() => {
                            const date = new Date(selectedSlot.start);
                            const userTimezoneOffset = date.getTimezoneOffset() * 60000;
                            return new Date(date.getTime() + userTimezoneOffset)
                        })()), 'E dd LLL HH:mm a')}
                        {' • '} {slotType.includes('virtual') ? 'Video consultation' : 'In person'}
                    </span>
                    </div>
                    <div style={{
                        color: 'var(--primary-color)',
                        fontWeight: 'bold',
                    }}>
                        {slotType}
                    </div>
                </div>
            </div>
            <p
                style={{ fontSize: theme.fontSize, color: theme.textColor }}
                className={BookingText}
                dangerouslySetInnerHTML={{ __html: bookingCompletedDescription }}
            />
        </div>
    );
};

export default BookingCompleted;

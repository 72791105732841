import React from 'react'
import { ErrorMessage } from '../../../styles/index.css';
import { useContextState } from '../../../context';

export default ({ error }) => {
    const { config: { theme } } = useContextState();
    return error ? (
        <span
            className={ErrorMessage}
            style={{
                color: theme.errorColor,
                fontSize: theme.errorFontSize
            }}
        >
            {error}
        </span>
    ) : null
}
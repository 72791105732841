import React, { useState } from 'react';
import InputField from './../common/input-field';
import Button from './../common/button';
import {useContextDispatch, useContextState} from '../../context';
import { FormTitle, FormContainerWrapper } from '../../styles/form.css';
import { FormButtonOverlapped, FormTextSection } from './styles.css';
import { setPatientFormValue } from '../../context/store/actions';
import { validator } from '../../utils/validator';

const ZipCodeSearchForm = ({ action, buttonDisabled = false }) => {
    const {
        config: {
            zipCodeSearchFormTitle,
            zipCodeSearchFormButtonText,
            zipCodeSearchFormText,
            theme,
        }
    } = useContextState();
    const [zipCode, setZipCode] = useState('')
    const [showError, setShowErr] = React.useState(false);
    const dispatch = useContextDispatch();

    const zipCodeErrors = validator('required|gbPostalCode', zipCode);

    return (
        <div className={FormContainerWrapper}>
            {
                zipCodeSearchFormTitle ? (
                    <h3
                        className={FormTitle}
                        style={{
                            fontSize: theme.titleFontSize,
                            color: theme.textColor,
                            textAlign: 'center',
                            marginTop: '-30px',
                        }}
                    >
                        {zipCodeSearchFormTitle}
                    </h3>
                ) : null
            }
            <div className={FormTextSection}>
                {zipCodeSearchFormText}
            </div>
            <InputField
                placeholder={'Your Postcode*'}
                label={zipCodeSearchFormButtonText}
                value={zipCode}
                onChange={ev => {
                    setZipCode(ev.target.value);
                    dispatch(setPatientFormValue({ key: 'zipCodeSearch', value: ev.target.value }))
                }}
                forceErr={showError}
                errors={showError ? zipCodeErrors : []}
            />
            <Button
                disabled={buttonDisabled}
                additionalClass={FormButtonOverlapped}
                text="Book Appointment"
                onClick={() => {
                    if (zipCodeErrors.length) {
                        setShowErr(true)
                    } else {
                        setShowErr(false)
                        action();
                    }
                }}
            />
        </div>
    );
};

export default ZipCodeSearchForm;

import React, { useState, createRef, useEffect } from 'react';
import { useContextState } from "../../../context";
import {
    FormControlFieldComponent,
    DropdownWrapper,
    DropDownList
} from "../../../styles/form.css";
import { ReactComponent as ArrowSvg } from '../../../assets/icons/arrow.svg'


const DropdownField = ({ options = [], onChange = () => null, placeholder = '', value = '', showArrow = false }) => {
    const [isFocused, setFocused] = useState(false);
    const { config: { theme } } = useContextState();
    let wrapperSelect = createRef();
    useEffect(() => {
        const handleOutsideClick = ev => {
            if (wrapperSelect && !wrapperSelect.contains(ev.target)) {
                setFocused(false)
            }
        }
        document.addEventListener('mousedown', handleOutsideClick)
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
    }, [wrapperSelect]);
    return (
        <div className={DropdownWrapper}>
            {showArrow ? (
                <div
                    className={FormControlFieldComponent}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: theme.textColor,
                        borderColor: theme.borderColor,
                        fontSize: theme.inputFontSize
                    }}
                    onClick={() => setFocused(true)}
                >
                    <div>
                        {value}
                    </div>
                    <ArrowSvg style={{
                        width: 15,
                        height: 15,
                        margin: 20,
                        transform: isFocused ? 'rotate(-90deg)' : 'rotate(90deg)'
                    }} />
                </div>
            ) : (
                <input
                    className={FormControlFieldComponent}
                    type={'text'}
                    value={value || ''}
                    placeholder={placeholder}
                    style={{
                        color: theme.textColor,
                        borderColor: theme.borderColor,
                        fontSize: theme.inputFontSize
                    }}
                    readOnly
                    onFocus={() => setFocused(true)}
                />
            )}
            <div
                className={DropDownList}
                style={{ display: isFocused ? 'block' : 'none' }}
                ref={ref => wrapperSelect = ref}
            >
                {options.map(option => (
                    <div
                        key={option.value}
                        onClick={() => {
                            onChange(option);
                            setFocused(false);
                        }}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DropdownField;

import React from 'react';
import { ContextProvider } from './context';
import MainContainer from "./containers/MainContainer";
import { defaultConfig } from "./utils/defaults";
import './index.external.css';
import { mergeDeep } from "./utils/helpers";

const App = ({ config = {}, setDispatch }) => {
    const settings = mergeDeep(defaultConfig, config);

    return (
        <ContextProvider config={settings} setDispatch={setDispatch}>
            <MainContainer />
        </ContextProvider>
    );
};

export default App;

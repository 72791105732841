import React from "react";
import {
    FormControlFieldLabel,
    DateInputFieldsWrapper
} from '../../../styles/form.css';
import {useContextState} from "../../../context";
import {getDays, getMonths, getYears} from "../../../utils/helpers";
import {ErrorMessage} from "../../../styles/index.css";
import DropdownField from "../dropdown-field";


const DateInput = ({
    onChange = () => null,
    date: { month, day, year },
    label,
    disabled = false,
   errors = [],
   forceErr = false,
}) => {
    const { config: { theme } } = useContextState();
    const [isTouched, setTouched] = React.useState(false);
    return (
        <div style={{ marginTop: 10 }}>
            {label ?
                (
                    <label
                        className={FormControlFieldLabel}
                        style={{
                            color: theme.textColor,
                            fontSize: theme.labelFontSize
                        }}
                    >
                        {label}
                    </label>
                )
                : null}
                <div className={DateInputFieldsWrapper}>
                    <DropdownField
                        options={getDays()}
                        placeholder={'Day'}
                        value={day ? getDays().find(d => d.value === day).label : null}
                        onChange={val => {
                            if (!isTouched) {
                                setTouched(true);
                            }
                            onChange({ year, day: val.value, month })
                        }}
                        disabled={disabled}
                    />
                    <DropdownField
                        options={getMonths()}
                        placeholder={'Month'}
                        value={month || month === 0 ? getMonths().find(mon => mon.value === month).label : null}
                        onChange={val => {
                            if (!isTouched) {
                                setTouched(true);
                            }
                            onChange({ year, day, month: val.value })
                        }}
                        disabled={disabled}
                    />
                    <DropdownField
                        options={getYears()}
                        placeholder={'Year'}
                        value={year ? getYears().find(y => y.value === year).label : null}
                        onChange={val => {
                            if (!isTouched) {
                                setTouched(true);
                            }
                            onChange({ year: val.value, day, month })
                        }}
                        disabled={disabled}
                    />
                </div>
            {
                errors.length && (isTouched || forceErr) ?
                    <span
                        className={ErrorMessage}
                        style={{
                            color: theme.errorColor,
                            fontSize: theme.errorFontSize
                        }}
                    >
                        {errors[0]}
                    </span>
                    : null
            }
        </div>
    );
};

export default DateInput;

export const GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP';
export const GO_BACK = 'GO_BACK';
export const SET_PATIENT_FORM_FIELD_VALUE = 'SET_PATIENT_FORM_FIELD_VALUE';
export const RESET_PATIENT_FORM = 'RESET_PATIENT_FORM';
export const RESET_STATE = 'RESET_STATE';
export const CHANGE_CALENDAR_CURRENT_DATE = 'CHANGE_CALENDAR_CURRENT_DATE';
export const SELECT_SLOT = 'SELECT_SLOT';
export const CHANGE_APPOINTMENT_TYPE = 'CHANGE_APPOINTMENT_TYPE';
export const BOOKING_COMPLETED = 'BOOKING_COMPLETED';
export const SET_COLUMNS_TO_SHOW = 'SET_COLUMNS_TO_SHOW';
export const SET_PRACTICE = 'SET_PRACTICE';
export const SET_PATIENT = 'SET_PATIENT';
export const SET_APPOINTMENT_TYPES = 'SET_APPOINTMENT_TYPES';
export const AUTH_ATTEMPT_TRIGGER = 'AUTH_ATTEMPT_TRIGGER';
export const SET_PRACTICES = 'SET_PRACTICES';

export const goToNextStep = () => ({
    type: GO_TO_NEXT_STEP
});

export const setPatientFormValue = ({ key, value }) => ({
    type: SET_PATIENT_FORM_FIELD_VALUE,
    payload: { key, value }
});

export const resetPatientForm = () => ({
    type: RESET_PATIENT_FORM
});

export const resetState = () => ({
    type: RESET_STATE
});

export const changeCalendarCurrentDate = date => ({
    type: CHANGE_CALENDAR_CURRENT_DATE,
    payload: date
});

export const selectSlot = slot => ({
    type: SELECT_SLOT,
    payload: slot
});

export const changeAppointmentType = type => ({
    type: CHANGE_APPOINTMENT_TYPE,
    payload: type
});

export const goBack = () => ({
    type: GO_BACK
});

export const bookingCompleted = () => ({
    type: BOOKING_COMPLETED
});

export const setColumnsToShow = (columns) => ({
    type: SET_COLUMNS_TO_SHOW,
    payload: columns
});

export const setPractice = (practice) => ({
    type: SET_PRACTICE,
    payload: practice
});

export const setPatient = (patient) => ({
    type: SET_PATIENT,
    payload: patient
});

export const setAppointmentTypes = (types) => ({
    type: SET_APPOINTMENT_TYPES,
    payload: types
});

export const authAttemptTrigger = () => ({
    type: AUTH_ATTEMPT_TRIGGER,
    payload: null
});

export const setPractices = (practices) => ({
    type: SET_PRACTICES,
    payload: practices
});

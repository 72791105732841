import React from 'react';
import TextareaField from './../common/textarea-field';
import { useContextState, useContextDispatch } from '../../context';
import Button from '../common/button';
import CheckboxField from '../common/checkbox-field';
import { setPatientFormValue } from '../../context/store/actions';
import { validator } from '../../utils/validator';
import { FormContainerWrapper, FormTitle } from '../../styles/form.css';
import { endOfYear, subYears, isAfter, isValid } from 'date-fns';
import PhoneInput from '../common/phone-input';
import DateInput from '../common/date-input-field';
import InputField from "../common/input-field";

const validateValues = (reason, phoneNumber, zipCode, terms, options) => {
    const { skipMobileField, showPostalCodeField } = options;
    const reasonErr = validator('minLength-15', reason);
    const phoneErr = skipMobileField ? [] : validator('required', phoneNumber);
    const termsAgreedErr = validator('required', terms);
    const zipCodeErr = showPostalCodeField ? validator('required|gbPostalCode', zipCode) : [];
    return {
        reasonErr,
        phoneErr,
        termsAgreedErr,
        zipCodeErr,
        isValid: !reasonErr.length && !phoneErr.length && !termsAgreedErr.length && !zipCodeErr.length,
    }
};

const getDate = (date) => {
    const dateString = `${date.year}-${date.month + 1}-${date.day}`;
    const dateValue = new Date(date.year, date.month, date.day);
    return isValid(dateValue) && (Object.values(date).filter(val => val !== null).length === 3) ?
        { date: dateValue, formattedDate: dateString }
    : null;
};

const renderErrors = (reasons, theme) => {
    return reasons.map((reason, key) => (
        <div
            key={`${reason}-${key}`}
            style={{
                color: theme.errorColor,
                fontSize: theme.errorFontSize,
                margin: '10px auto'
            }}
        >
            {reason}
        </div>
    ));
};

const ReasonForm = ({ action, buttonDisabled = false, skipMobileField = false }) => {
    const [isLoading, setLoading] = React.useState(false)
    const {
        patientForm: { reason, dayOfBirth, phoneNumber, zipCode },
        config: {
            theme,
            termsAndConditionsLink,
            defaultCountry,
            patientFormButtonText,
            reasonFormTitle,
            hideReasonToVisitField,
            showPostalCodeField,
        },
        selectedPractice,
    } = useContextState();
    const maxDate = endOfYear(subYears(new Date(), 18));
    const [phoneValidFormat, setPhoneValidFormat] = React.useState(true);
    const [dateValidFormat, setDateValidFormat] = React.useState(true);
    const [dateFormatErrors, setDateFormatErrors] = React.useState([]);
    const [showError, setShowErr] = React.useState(false);
    const [termsAgreed, setTerms] = React.useState(false);
    const dispatch = useContextDispatch();
    const [apiErrors, setApiErrors] = React.useState([]);
    const { isValid, reasonErr, phoneErr, zipCodeErr, termsAgreedErr } = validateValues(
        reason,
        phoneNumber,
        zipCode,
        termsAgreed,
        { skipMobileField, showPostalCodeField });
    const location = selectedPractice?.location;

    return (
        <div className={FormContainerWrapper}>
            {
                reasonFormTitle ? (
                    <h3
                        className={FormTitle}
                        style={{
                            fontSize: theme.titleFontSize,
                            color: theme.textColor
                        }}
                    >
                        {reasonFormTitle}
                    </h3>
                ) : null
            }
            {
                skipMobileField ? null : (
                    <PhoneInput
                        label={'Mobile'}
                        defaultCountry={location && location.country ? location.country.toLowerCase() : defaultCountry}
                        format
                        onChange={(data) => {
                            setPhoneValidFormat(data.isValid);
                            dispatch(setPatientFormValue({ key: 'phoneNumber', value: data.value }))
                        }}
                        errors={showError && phoneErr}
                        forceErr={showError}
                    />
                )
            }
            <DateInput
                onChange={date => dispatch(setPatientFormValue({ key: 'dayOfBirth', value: date }))}
                date={dayOfBirth}
                label={'Date of birth (Patients must be 18 years or over)'}
                errors={showError && !dateValidFormat ? dateFormatErrors : []}
                forceErr={showError}
            />
            {hideReasonToVisitField ? null : (
                <TextareaField
                    label="Reason for visit"
                    value={reason}
                    onChange={(ev) => dispatch(setPatientFormValue({ key: 'reason', value: ev.target.value }))}
                    errors={reasonErr}
                    forceErr={showError}
                />
            )}
            {showPostalCodeField ? (
                <InputField
                    label="Postal code"
                    value={zipCode}
                    onChange={ev => dispatch(setPatientFormValue({ key: 'zipCode', value: ev.target.value }))}
                    errors={showError && zipCodeErr}
                    forceErr={showError}
                />
            ) : null}
            <CheckboxField
                checked={termsAgreed}
                onChange={() => setTerms(!termsAgreed)}
                errors={termsAgreedErr}
                forceErr={showError}
                label={(
                    <span>
                        I agree to {' '}
                        <a
                            href={termsAndConditionsLink}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: theme.textColor }}
                        >
                            T&C
                        </a>
                    </span>
                )}
            />
            {renderErrors(apiErrors, theme)}
            <Button
                disabled={isLoading || buttonDisabled}
                text={patientFormButtonText}
                onClick={() => {
                    setShowErr(!isValid || !phoneValidFormat || (showPostalCodeField));
                    const dob = getDate(dayOfBirth);
                    if (!dob) {
                        setDateFormatErrors(['Invalid date']);
                        setShowErr(true);
                        setDateValidFormat(false);
                        return;
                    }
                    if (isValid && !apiErrors.length && phoneValidFormat && dob) {
                        if (isAfter(dob.date, maxDate)) {
                            setDateFormatErrors(['Patients must be 18 years or over']);
                            setShowErr(true);
                            setDateValidFormat(false);
                        } else {
                            action({
                                setLoading,
                                setErrors: setApiErrors,
                            });
                        }
                    }
                }}
            />
        </div>
    );
};

export default ReasonForm;

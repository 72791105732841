import React from 'react';
import {
    ProgressWrapper,
    ProgressItem,
    ProgressItemNext,
    ProgressChangeStep
} from './index.css';
import { ReactComponent as Arrow } from '../../../assets/icons/arrow.svg'
import { useContextState } from "../../../context";

const Progress  = ({ steps, currentStep, onGoBack, showGoBack }) => {
    const getClass = index => {
        if (index > currentStep) return ProgressItemNext;
        return ''
    };
    const { config: { theme } } = useContextState();
    return (
        <div className={ProgressWrapper}>
            { showGoBack ? (
                <span
                    style={{ background: theme.primaryBackground }}
                    onClick={onGoBack}
                    className={ProgressChangeStep}>
                    <Arrow/>
                </span>
            ) : null}
            {
                [...Array(steps)].map((step, index) => (
                    <React.Fragment key={index}>
                        <span
                            className={`${ProgressItem} ${getClass(index)}`}
                            style={{ backgroundColor: theme.progressColor }}
                        />
                    </React.Fragment>
                ))
            }
        </div>
    );
};


export default Progress;

